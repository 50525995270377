import 'reflect-metadata'
import { AppContainer } from 'react-hot-loader'
import React from 'react'
import { render } from 'react-dom'
import Loadable from 'react-loadable'
import FairsService from '@webmotors/wm-portal-lib-api-fairs'
import { config } from 'config/config'
import { useTargetApi as targetApi } from '@/modern/hooks/useTargetApi'

import '@/scss/normalize.css'
import '@/scss/global.scss'
import '@/scss/layout.scss'

import { App as _App } from '@webmotors/wm-portal-business'
import App from './App'

const {
  env,
  novaRB
} = config.abTest

FairsService.setConfigurationLib({
  baseURLFairs: config.urlApi,
})

_App.init({
  apiUrl: config.urlApi,
  authCookieName: config.cookieJwt,
  cookieDomains: config.cookieDomains,
  logging: false,
})

const mboxes = [
 {
    name: novaRB.mboxName,
    keys: novaRB.keys
  }
]

const targetApiPromise = () =>
  new Promise(resolve =>
    targetApi({
      mboxes,
      env,
      callback: () => resolve(),
    })
  )

Promise.all([targetApiPromise(), Loadable.preloadReady()]).then(() => {
  render(
    <AppContainer>
      <App />
    </AppContainer>,
    document.getElementById('root')
  )
  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./App', () => {
      render(
        <AppContainer>
          <App />
        </AppContainer>,
        document.getElementById('root')
      )
    })
  }
})
