import axios from 'axios'
import Immutable from 'immutee'

import { config } from 'config/config'
import { getApiUrl, getChannelParameter, hasPandora, hasMediaZeroKm } from '../utils/utils'

export const FETCH_SEARCH_RESULT = 'buyer/searchResult/FETCH_SEARCH_RESULT'
export const FETCH_SEARCH_RESULT_FAILURE =
  'buyer/searchResult/FETCH_SEARCH_RESULT_FAILURE'

const initialState = {
  fetched: false,
  result: [],
  error: null,
  isPending: true,
}

export default function reducer(state = initialState, action) {
  const immutable = Immutable(state)
  switch (action.type) {
    case FETCH_SEARCH_RESULT:
      return immutable
        .set('result', action.payload)
        .set('requested', action.requested)
        .set('fetched', true)
        .set('isPending', false)
        .done()

    case FETCH_SEARCH_RESULT_FAILURE:
      return immutable
        .set('error', action.payload)
        .set('requested', action.requested)
        .set('result', [])
        .set('fetched', false)
        .set('isPending', false)
        .done()

    default:
      return state
  }
}

function handleURL(data) {
  const { urlApp } = config
  const {
    url,
    vehicleType,
    category,
    hotDeal,
    actualPage,
    displayPerPage,
    order,
    showMenu,
    showCount,
    showBreadCrumb,
    testAB,
    returnUrl
  } = data
  const separator = decodeURIComponent(url).includes('?') ? '&' : '?'
  const hasNeeds = decodeURIComponent(url).includes('necessidade')
  const needs =
    category && category.NomeCategoria && !hasNeeds
      ? `${separator}necessidade=${category.NomeCategoria}`
      : ''
  const hotDeals =
    hotDeal && hotDeal.NomeHotDeal && !hasNeeds
      ? `${separator}feirao=${hotDeal.NomeHotDeal}`
      : ''

  const channelParameter = getChannelParameter()
  const pandora = hasPandora()
  const mediaZeroKm = hasMediaZeroKm()
  const queries = [
    `&actualPage=${actualPage}`,
    `&displayPerPage=${displayPerPage}`,
    `&order=${order}`,
    `&showMenu=${showMenu}`,
    `&showCount=${showCount}`,
    `&showBreadCrumb=${showBreadCrumb}`,
    `&testAB=${testAB}`,
    `&returnUrl=${returnUrl}`,
    channelParameter,
    `&pandora=${pandora}`,
    `&mediaZeroKm=${mediaZeroKm}`,
  ].join('')

  return `${getApiUrl()}search/${vehicleType}?url=${urlApp}${url}${needs}${hotDeals}${queries}`
}

export function fetchSearchResult(data) {
  const url = handleURL(data)

  return dispatch => {
    return axios
      .get(url, {
        headers: {
          'X-Channel-Id': 'webmotors.buyer.desktop.ui',
        },
      })
      .then(res => {
        dispatch({
          type: FETCH_SEARCH_RESULT,
          payload: res.data,
          requested: data,
        })
      })
      .catch(err => {
        dispatch({
          type: FETCH_SEARCH_RESULT_FAILURE,
          payload: err.stack,
        })
      })
  }
}
